// import JetCodeVideo from '../assets/videos/saas_demo.mp4'

const videoesUseCase = [
    {
        id: 1,
        title: "snake game",
        videoId: "G1-ZuhjnvsM"
    },
    {
        id: 2,
        title: "portfolio demo",
        videoId: "2SQfMADCGDk"
    }

]
export default videoesUseCase